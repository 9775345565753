import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@react-pdf/styled-components';
import { Page, Document, PDFViewer } from '@react-pdf/renderer';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import numeral from 'numeral';
import _ from 'lodash';
import { findReservaById } from '../../pages/reserva/ReservaAPI';
import { getUserProfile } from '../../store/reducers/user';
import Logo from '../../static/img/logo-jbb.png';
import LogoJmb from '../../static/img/logo-jmb-preto.png';

const View = styled.View`
  margin: 10px;
  padding: 10px;
`;

const ContainerTitle = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: -20px;
`;

const Image = styled.Image`
  width: 100px;
  height: 85px;
`;

const TitleLogo = styled.Text`
  font-size: 18px;
  font-weight: bold;
  margin-left: 48px;
`;

const NomeEmp = styled.Text`
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
`;

const TextData = styled.Text`
  font-size: 9px;
  text-align: right;
`;

const TextCab = styled.Text`
  font-size: 12px;
  text-align: center;
  padding: 2px;
`;

const Title = styled.Text`
  margin-top: 2px;
  font-size: 18px;
  padding: 10px;
  font-weight: bold;
  text-align: center;
`;

const Container = styled.View`
  border: solid 1px;
  padding: 1px 6px 7px;
`;

const TextNormal = styled.Text`
  font-size: 12px;
  margin-top: 6px;
`;

const Strong = styled.Text`
  font-weight: bold;
  font-size: 14px;
`;

const Table = styled.View`
  display: table;
  width: auto;
`;

const TableRow = styled.View`
  margin: auto;
  flex-direction: row;
`;

const TableCol = styled.View`
  width: 25%;
  border-left-width: 0;
  border-top-width: 0;
`;

const TableCel = styled.Text`
  text-align: left;
  margin: auto;
  margin-top: 5px;
  font-size: 10px;
`;

const Assinatura = styled.Text`
  margin-top: 105px;
`;

class ReservaPrintComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reserva: {},
      reservaItens: [],
      compradores: [],
      vrTotLote: 0,
      user: {},
      dtAtual: null,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id } = this.props.match.params;
    this.fetchReserva(parseInt(id, 10));
    moment.locale('pt-br');
  }

  getStatusReserva = status => {
    let descStatus;
    if (status === 1) {
      descStatus = 'Aguardando Aprovação';
    } else if (status === 2) {
      descStatus = 'Aprovada';
    } else if (status === 3) {
      descStatus = 'Reprovada';
    } else if (status === 4) {
      descStatus = 'Reprovada Exclusão Contrato';
    } else if (status === 5) {
      descStatus = 'Reprovada Distrato';
    } else if (status === 6) {
      descStatus = 'Pendente Documentação';
    } else {
      descStatus = 'Status Desconhecido';
    }
    this.setState({
      descStatus,
    });
  };

  fetchReserva = id => {
    try {
      findReservaById(id).then(bundle => {
        this.setState(
          {
            reserva: {
              id_lcto: bundle.reserva.id_lcto,
              idProd: bundle.reserva.id_produto,
              idCorretor: bundle.reserva.id_corretor,
              vrEnt: `${bundle.reserva.vr_entrada}`,
              dtEnt: moment(bundle.reserva.dt_entrada).format('YYYY-MM-DD'),
              numParc: bundle.reserva.num_parc,
              vrParc: bundle.reserva.vr_parc,
              diaPag: bundle.reserva.dia_pag,
              idEmpresa: bundle.reserva.id_empresa,
              fgStatus: bundle.reserva.fg_status,
              dtReserva: moment(bundle.reserva.dt_reserva).format('YYYY-MM-DD'),
              numParcEnt: bundle.reserva.num_parc_ent,
              dtPrimeiraParc: moment(bundle.reserva.dt_primeira_parc).format('YYYY-MM-DD'),
              obsEnt: bundle.reserva.obs_ent,
              descStatus: this.getStatusReserva(bundle.reserva.fg_status),
            },
            reservaItens: bundle.itens.map(it => ({
              idLote: it.id_lote,
              vrLote: it.vrLote,
              quadra: it.quadra,
              lote: it.lote,
              produto: it.produto,
            })),
            compradores: bundle.compradores.map(co => ({
              nome: co.nome,
              rg: co.rg,
              cpf: co.cpf,
              estadoCivil: co.estado_civil,
              idNacionalidade: co.id_nacionalidade,
              nacionalidade: co.nacionalidade,
              idProfissao: co.id_profissao,
              profissao: co.profissao,
              locTrab: co.local_trab,
              fone1: co.fone1,
              fone2: co.fone2,
              fone3: co.fone3,
              email: co.email,
              compTitular: co.comp_titular,
              idReserva: co.id_reserva,
              logradouro: co.logradouro,
              numero: co.numero,
              bairro: co.bairro,
              cidade: co.cidade,
              estado: co.estado,
              cep: co.cep,
              complemento: co.complemento,
              documentos: co.documentos,
              dtNascimento: moment(co.dt_nascimento).format('YYYY-MM-DD'),
              obs: co.obs,
            })),
            dtAtual: moment(new Date())
              .locale('pt-br')
              .format('DD/MM/YYYY HH:mm:ss'),
          },
          this.totalizaVrLote
        );
      }, this.getNacionalidadeDescricao);
    } catch (err) {
      const { error } = this.state;
      this.setState({
        ...error,
        error: err.message,
      });
    }
  };

  priceFormatter = value => {
    numeral.locale('pt-br');
    const formatado = numeral(value).format('$ 0,0.00');
    return formatado;
  };

  totalizaVrLote = () => {
    const sum = _.sumBy(this.state.reservaItens, function totLote(reserva) {
      return reserva.vrLote;
    });
    this.setState({
      vrTotLote: sum,
    });
  };

  render() {
    const { user } = this.props;
    const { reservaItens, compradores, vrTotLote, dtAtual, descStatus } = this.state;

    // reserva
    const {
      id_lcto: idLcto,
      vrEnt,
      dtEnt,
      numParc,
      vrParc,
      diaPag,
      dtReserva,
      numParcEnt,
      dtPrimeiraParc,
    } = this.state.reserva;

    return (
      <div className="App">
        <h1>Código Reserva: {idLcto}</h1>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Ficha Reserva</BreadcrumbItem>
        </Breadcrumb>
        <PDFViewer width={1000} height={800}>
          <Document>
            <Page size="A4" wrap>
              <View wrap>
                <TextData>{dtAtual}</TextData>
                <NomeEmp>
                  {process.env.REACT_APP_IS_JMB === 'S'
                    ? process.env.REACT_APP_EMP_LOGIN_JMB
                    : process.env.REACT_APP_EMP_LOGIN_JBB}
                </NomeEmp>
                <TextCab>Reserva de Loteamento</TextCab>
                <TextCab>Cód. Reserva: {idLcto}</TextCab>
                <ContainerTitle>
                  <Image src={process.env.REACT_APP_IS_JMB === 'S' ? LogoJmb : Logo} />
                  <TitleLogo>Proposta para compra do imóvel</TitleLogo>
                </ContainerTitle>
                <Title>Condições da Venda</Title>
                <Container>
                  <Table>
                    <TableRow>
                      <TableCol style={{ width: '70%', marginRight: '3px' }}>
                        <TableCel>
                          <TextNormal>
                            <Strong>Corretor:</Strong> {user.nome}
                          </TextNormal>
                        </TableCel>
                      </TableCol>
                      <TableCol>
                        <TableCel>
                          <TextNormal>
                            <Strong>CRECI:</Strong> {user.creci}
                          </TextNormal>
                        </TableCel>
                      </TableCol>
                    </TableRow>
                  </Table>
                  <TextNormal>
                    <Strong>Data Reserva:</Strong> {moment(dtReserva).format('DD/MM/YYYY')}{' '}
                    {'                                                           '}
                    <Strong>Status:</Strong> {descStatus}
                  </TextNormal>
                  <TextNormal>
                    <Strong>Número Parcelas Ent.:</Strong> {numParcEnt} {'                '}
                    <Strong>Valor Entrada:</Strong> {this.priceFormatter(vrEnt)}{' '}
                    {vrEnt > 0 ? '            ' : '                     '}
                    <Strong>Data Entrada:</Strong>{' '}
                    {moment(dtEnt).format('DD/MM/YYYY') === '31/12/0000' ? '' : moment(dtEnt).format('DD/MM/YYYY')}
                  </TextNormal>
                  <TextNormal>
                    <Strong>Número Parcelas:</Strong> {numParc} {'                      '}
                    <Strong>Valor Parcela:</Strong> {this.priceFormatter(vrParc)} {'              '}
                    <Strong>Dia Pagamento:</Strong> {diaPag}
                  </TextNormal>
                  <TextNormal>
                    <Strong>Data Primeira Parcela:</Strong> {moment(dtPrimeiraParc).format('DD/MM/YYYY')}
                  </TextNormal>
                </Container>
                <Title>Lote(s)</Title>
                <Container>
                  <Table>
                    <TableRow>
                      <TableCol style={{ width: '80%', marginRight: '10px' }}>
                        <TableCel>
                          <Strong>Produto</Strong>
                        </TableCel>
                      </TableCol>
                      <TableCol>
                        <TableCel>
                          <Strong>Quadra</Strong>
                        </TableCel>
                      </TableCol>
                      <TableCol>
                        <TableCel>
                          <Strong>Lote</Strong>
                        </TableCel>
                      </TableCol>
                      <TableCol>
                        <TableCel>
                          <Strong>Vr. Lote</Strong>
                        </TableCel>
                      </TableCol>
                    </TableRow>
                    {reservaItens.map(item => {
                      return (
                        <TableRow key={item.idLote}>
                          <TableCol style={{ width: '80%', marginRight: '10px' }}>
                            <TableCel>{item.produto}</TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>{item.quadra}</TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>{item.lote}</TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>{this.priceFormatter(item.vrLote)}</TableCel>
                          </TableCol>
                        </TableRow>
                      );
                    })}
                  </Table>
                  <TextNormal>
                    <Strong>R$ Valor Total Lote(s): {this.priceFormatter(vrTotLote)}</Strong>
                  </TextNormal>
                </Container>
                <Title>Dados do(s) comprador(es)</Title>
                {compradores.map(comp => {
                  return (
                    <Container key={comp.cpf}>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '70%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Nome:</Strong> {comp.nome}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>RG:</Strong> {comp.rg}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>CPF:</Strong> {comp.cpf}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '25%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Titular Compra:</Strong> {comp.compTitular === 'S' ? 'SIM' : 'NÃO'}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '35%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Estado Cívil:</Strong> {comp.estadoCivil}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '40%' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Nacionalidade:</Strong> {comp.nacionalidade}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <TextNormal>
                        <Strong>Profissão:</Strong> {comp.profissao}
                      </TextNormal>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '38%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Loc. Trab.:</Strong> {comp.locTrab}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>Fone1:</Strong> {comp.fone1}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>Fone2:</Strong> {comp.fone2}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>Fone3:</Strong> {comp.fone3}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '60%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Email:</Strong> {comp.email}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>Nascimento:</Strong> {moment(comp.dtNascimento).format('DD/MM/YYYY')}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '45%', marginRight: '5px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Rua:</Strong> {comp.logradouro}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '15%', marginRight: '5px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Número:</Strong> {comp.numero}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '40%' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Bairro:</Strong> {comp.bairro}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableCol style={{ width: '35%', marginRight: '5px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Cidade:</Strong> {comp.cidade}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '12%', marginRight: '5px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Estado:</Strong> {comp.estado}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol style={{ width: '15%', marginRight: '10px' }}>
                            <TableCel>
                              <TextNormal>
                                <Strong>Cep:</Strong> {comp.cep}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                          <TableCol>
                            <TableCel>
                              <TextNormal>
                                <Strong>Complemento:</Strong> {comp.complemento}
                              </TextNormal>
                            </TableCel>
                          </TableCol>
                        </TableRow>
                      </Table>
                      <TextNormal>
                        <Strong>Observações:</Strong> {comp.obs}
                      </TextNormal>
                    </Container>
                  );
                })}
                <Assinatura>_______________________{'                 '} _______________________</Assinatura>
                <TextNormal style={{ marginLeft: '55' }}>
                  Assinatura do corretor {'                                                         '} Assinatura do
                  comprador
                </TextNormal>
              </View>
            </Page>
          </Document>
        </PDFViewer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getUserProfile(state),
  };
};

ReservaPrintComponent.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, null)(ReservaPrintComponent);
