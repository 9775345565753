import React, { Component } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { API_URL } from '../pages/reserva/ReservaAPI';

const queryProfissoes = `
  query($descProfissao: String!) {
    findProfissoes(descProfissao: $descProfissao) {
      idprofissao
      descricao
    }
  }
`;

const variables = {
  descProfissao: '',
};

class SearchProfissao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profissoes: [],
    };
  }

  componentDidMount() {
    this.findProfissoes();
  }

  findProfissoes = async () => {
    try {
      const response = await axios.post(API_URL, {
        query: queryProfissoes,
        variables,
      });

      this.setState({
        profissoes: response.data.data.findProfissoes,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const profissoes = this.state.profissoes.map(profissao => (
      <option key={profissao.idprofissao} value={profissao.idprofissao}>
        {profissao.descricao}
      </option>
    ));

    return (
      <AvField type="select" name="profissoes" label="Profissão" {...this.props}>
        {profissoes}
      </AvField>
    );
  }
}

export default SearchProfissao;
