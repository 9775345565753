import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Switch, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import ReservaListComponent from './pages/reserva/ReservaListComponent';
import ReservaCreateComponent from './pages/reserva/ReservaCreateComponent';
import ReservaPrintComponent from './pages/reserva/ReservaPrintComponent';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Perfil from './pages/perfil/Perfil';
import RecuperarSenha from './pages/RecuperarSenha';
import AlterarSenha from './pages/AlterarSenha';
import Loteamento from './pages/Loteamentos';
import api from './api';
import { getUserProfile } from './store/reducers/user';
import { loginSuccess } from './store/actions/authActions';
import Footer from './components/Footer';
import './index.css';

class App extends Component {
  state = {
    loading: true,
    loadError: null,
  };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    try {
      const user = await api.auth.me();
      this.setState({ loading: false });
      this.props.loginSuccess(user);
    } catch (err) {
      this.setState({ loading: false, loadError: err.message });
    }
  };

  render() {
    const { loading, loadError } = this.state;
    const { user } = this.props;
    let content;

    if (loading) {
      content = <div>Aguarde, buscando dados...</div>;
    } else if (loadError) {
      content = <div>Erro ao comunicar com servidor: {loadError}</div>;
    } else if (user) {
      content = (
        <div>
          <Navigation />
          <Switch>
            <Route path="/" exact component={ReservaListComponent} />
            <Route path="/perfil" component={Perfil} />
            <Route path="/addReserva" component={ReservaCreateComponent} />
            <Route path="/editReserva/:id" component={ReservaCreateComponent} />
            <Route path="/printReserva/:id" component={ReservaPrintComponent} />
            <Route path="/updatePass" component={AlterarSenha} />
            <Route path="/loteamento" component={Loteamento} />
            <Route path="/" component={NotFound} />
          </Switch>
          <Footer />
        </div>
      );
    } else {
      content = (
        <Switch>
          <Route path="/resetPass" component={RecuperarSenha} />
          <Route path="/" component={Login} />
        </Switch>
      );
    }

    return content;
  }
}

App.defaultProps = {
  user: null,
};

App.propTypes = {
  user: PropTypes.shape({}),
  loginSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: getUserProfile(state),
});

export default withRouter(connect(mapStateToProps, { loginSuccess })(App));
