import React from 'react';
import { Container } from 'reactstrap';

function Footer() {
  return (
    <footer>
      <Container>
        <div className="text-center">
          <small>
            <a href="https://www.alpha.com.vc" style={{ textDecoration: 'none', color: 'black' }}>
              Copyright - Alpha Sistemas para Empresas - 2023
            </a>
          </small>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
