import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../App.css';

function NotFound() {
  return (
    <div className="body-not-found page-wrap-not-found d-flex flex-row align-items-center">
      <Container>
        <Row>
          <Col md="12" className="text-center">
            <h2>Oops!</h2>
            <span className="display-1 d-block">404</span>
            <div className="mb-4 lead">A página que você está procurando não foi encontrada.</div>
            <a href="/" className="btn btn-primary btn-lg">
              <span className="glyphicon glyphicon-home" />
              Voltar para página inicial{' '}
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default NotFound;
