import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
} from 'reactstrap';
import api from '../api';
import Logo from '../static/img/logo-jbb.png';
import LogoJmb from '../static/img/logo-jmb.png';
import { loginSuccess } from '../store/actions/authActions';
// import Background from '../static/img/background.jpeg';
import '../../src/Login.css';

class Login extends Component {
  state = {
    email: '',
    password: '',
    loadError: null,
    loading: false,
  };

  handleSubmit = async ev => {
    ev.preventDefault();
    const { email, password } = this.state;
    if (!email) {
      this.setState({ loadError: 'Por favor, informe o e-mail' });
      return;
    }
    if (!password) {
      this.setState({ loadError: 'Por favor, informe a senha' });
      return;
    }
    this.setState({ loading: true, loadError: null });
    try {
      const user = await api.auth.login({ email, password });
      this.setState({ loading: false });
      this.props.loginSuccess(user);
    } catch (err) {
      this.setState({ loading: false, loadError: err.message });
    }
  };

  handleChange = ev => {
    this.setState({ [ev.target.name]: ev.target.value });
  };

  render() {
    const { loading, loadError, email, password } = this.state;
    return (
      <div className="fundo-login">
        <Container>
          <Row>
            <Col sm="12" md={{ size: 6, offset: 3 }} className="mt-5">
              <Card body style={{ borderColor: '#333' }} className="card-login">
                <CardBody>
                  <CardTitle className="text-center">
                    <img
                      src={process.env.REACT_APP_IS_JMB === 'S' ? LogoJmb : Logo}
                      alt="Logotipo"
                      style={{ marginBottom: '20px' }}
                    />
                    <h4 className="label">
                      {process.env.REACT_APP_IS_JMB === 'S'
                        ? process.env.REACT_APP_EMP_LOGIN_JMB
                        : process.env.REACT_APP_EMP_LOGIN_JBB}
                    </h4>
                  </CardTitle>
                  <Form onSubmit={this.handleSubmit}>
                    {loadError && <Alert color="danger">Erro ao efetuar login: {loadError}</Alert>}
                    <fieldset disabled={loading}>
                      <FormGroup>
                        <Label for="email" className="label">
                          E-mail
                        </Label>
                        <Input type="text" name="email" id="email" value={email} onChange={this.handleChange} />
                      </FormGroup>
                      <FormGroup>
                        <Label for="senha" className="label">
                          Senha
                        </Label>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          value={password}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <Button color="success" block>
                        Entrar
                      </Button>
                    </fieldset>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

Login.propTypes = {
  loginSuccess: PropTypes.func.isRequired,
};

export default connect(null, { loginSuccess })(Login);
