import moment from 'moment';
// import _ from 'lodash';
import { saveReservaAPI } from '../../pages/reserva/ReservaAPI';
// import { saveCompradorSuccess, saveCompradorError, saveCompradorRequest } from './saveCompradorAction';

export const saveReservaRequest = () => {
  return {
    type: 'SAVE_RESERVA_REQUEST',
    loading: true,
    error: null,
  };
};

export const saveReservaSuccess = idReserva => {
  return {
    type: 'SAVE_RESERVA_SUCCESS',
    idReserva,
    loading: false,
    error: null,
  };
};

export const saveReservaError = message => {
  return {
    type: 'SAVE_RESERVA_ERROR',
    loading: false,
    message,
  };
};

const toNumber = v => {
  if (v === '') return null;
  if (typeof v === 'string') return parseFloat(v.replace('.', '').replace(',', '.'), 10);
  if (isNaN(v)) throw new Error(`Não é um número válido: ${v}`);
  return v;
};

export const saveReserva = payload => {
  const data = { ...payload };

  data.reserva.vrEnt = toNumber(data.reserva.vrEnt);
  data.reserva.vrParc = toNumber(data.reserva.vrParc);

  if (data.reserva.numParcEnt === '') {
    data.reserva.numParcEnt = 0;
  }

  data.reserva.dtEnt = moment(data.reserva.dtEnt);
  data.reserva.dtReserva = moment(data.reserva.dtReserva);
  data.reserva.dtPrimeiraParc = moment(data.reserva.dtPrimeiraParc);

  const reservaEdit = {
    id_lcto: data.reserva.id_lcto,
    id_corretor: data.reserva.idCorretor,
    vr_entrada: data.reserva.vrEnt,
    dt_entrada: data.reserva.dtEnt,
    num_parc: data.reserva.numParc,
    vr_parc: data.reserva.vrParc,
    dia_pag: data.reserva.diaPag,
    id_empresa: data.reserva.idEmpresa,
    fg_status: data.reserva.fgStatus,
    dt_reserva: data.reserva.dtReserva,
    num_parc_ent: data.reserva.numParcEnt,
    dt_primeira_parc: data.reserva.dtPrimeiraParc,
    obs_ent: data.reserva.obsEnt,
  };

  const itensEdit = data.reservaItens.map(item => ({
    id_lote: item.idLote,
    vr_lote: item.vrLote,
  }));

  const compradoresEdit = data.compradores.map(comp => ({
    nome: comp.nome,
    rg: comp.rg,
    cpf: comp.cpf,
    estado_civil: comp.estadoCivil,
    id_nacionalidade: comp.idNacionalidade,
    id_profissao: comp.idProfissao,
    local_trab: comp.locTrab,
    fone1: comp.fone1,
    fone2: comp.fone2,
    fone3: comp.fone3,
    email: comp.email,
    comp_titular: comp.compTitular,
    logradouro: comp.logradouro,
    numero: comp.numero,
    bairro: comp.bairro,
    cidade: comp.cidade,
    estado: comp.estado,
    cep: comp.cep,
    complemento: comp.complemento,
    documentos: [...comp.documentos],
    dt_nascimento: moment(comp.dtNascimento),
    obs: comp.obs,
    conjuge: comp.conjuge,
    nacionalidade: '',
    profissao: '',
  }));

  return dispatch => {
    dispatch(saveReservaRequest());
    return saveReservaAPI(reservaEdit, itensEdit, compradoresEdit)
      .then(response => dispatch(saveReservaSuccess(response)))
      .catch(err => dispatch(saveReservaError(err.message)));
  };
};
