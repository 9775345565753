import React, { Component } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { API_URL } from '../pages/reserva/ReservaAPI';

const queryNacionalidade = `
  query($descNacionalidade: String!) {
    findNacionalidades(descNacionalidade: $descNacionalidade) {
      idnacionalidade
      descricao
    }
  }
`;

const variables = {
  descNacionalidade: '',
};

class SearchNacionalidade extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nacionalidades: [],
    };
  }

  componentDidMount() {
    this.findNacionalidades();
  }

  findNacionalidades = async () => {
    try {
      const response = await axios.post(API_URL, {
        query: queryNacionalidade,
        variables,
      });

      this.setState({
        nacionalidades: response.data.data.findNacionalidades,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const nacionalidades = this.state.nacionalidades.map(nacionalidade => (
      <option key={nacionalidade.idnacionalidade} value={nacionalidade.idnacionalidade}>
        {nacionalidade.descricao}
      </option>
    ));

    return (
      <AvField type="select" name="lotes" label="Nacionalidade" {...this.props}>
        {nacionalidades}
      </AvField>
    );
  }
}

export default SearchNacionalidade;
