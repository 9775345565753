import React from 'react';
import {
  Container,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';

const RecuperarSenha = () => {
  return (
    <Container>
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} className="mt-5">
          <Card body style={{ borderColor: '#333' }}>
            <CardBody>
              <CardTitle className="text-center">
                <strong>Recuperar Senha</strong>
              </CardTitle>
              <CardSubtitle className="text-center mt-4 mb-5">
                <h4>Esqueceu sua senha?</h4>
                <p>Digite seu e-mail e nós lhe enviaremos instruções sobre como redefinir sua senha.</p>
              </CardSubtitle>
              <Form>
                <FormGroup>
                  <Label for="email">E-mail</Label>
                  <Input type="text" name="email" id="email" required />
                </FormGroup>
                <Button color="primary" block>
                  Recuperar Senha
                </Button>
                <div>
                  <Link to="/" className="text-center d-block small mt-3">
                    Página de login
                  </Link>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default RecuperarSenha;
