const upload = file => {
  const data = new FormData();
  data.append('documento', file);

  return fetch('/upload', {
    method: 'post',
    body: data,
  }).then(res => res.text());
};

export default {
  upload,
};
