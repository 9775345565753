import axios from 'axios';
import { graphqlRequest } from '../../api/request';

// Back-end
export const API_URL = '/graphql';

const queryGetVrPrestacao = `
  query($vrCapital: Float!, $vrTaxaJuros: Float!, $numParc: Int!) {
    getVrPrestacao(vrCapital: $vrCapital, vrTaxaJuros: $vrTaxaJuros, numParc: $numParc)
  }
`;

const queryGetEndByCep = `
  query($cep: Int!) {
    findEnderecoByCep(cep: $cep) {
      cep
      logradouro
      bairro
      complemento
      uf
      cidade    
    }
  }
`;

const queryAtualizaReservaLoteTemp = `
  mutation
    atualizaReservaLoteTemp($idLote: Int!) {
      atualizaReservaLoteTemp(id_lote: $idLote)
    }
`;

const queryDeleteDocComp = `
  mutation
    deleteDocComp($id: String!) {
      deleteDocComp(id: $id)
    }
`;

const querySaveReserva = `
  mutation ($reserva: ReservaInput!, $itens: [ReservaItensInput]!, $compradores: [CompradorInput]!) {
    saveReserva(reserva: $reserva, itens: $itens, compradores: $compradores)
  }    
`;

const queryDeletaLoteTemp = `
  mutation
    deleteLoteTemp($id_lote: Int!) {
      deleteLoteTemp(id_lote: $id_lote)
    }
`;

const queryReservaLoteTemp = `
  mutation
    reservaLoteTemp($id_lote: Int!) {
      reservaLoteTemp(id_lote: $id_lote)
    }
`;

const queryFindReserva = `query($idCorretor: Int!) {
  findReservaByIdCorretor(id_corretor: $idCorretor) {
    id_lcto
    produto
    vr_lote
    fg_status
  }
}`;

const queryFindReservaById = `
query($id_lcto: Int!) {  
  findReservaById(id_lcto: $id_lcto) {
    reserva {
      dia_pag
      dt_entrada
      fg_status
      id_corretor
      id_empresa
      id_lcto
      num_parc
      vr_entrada
      vr_lote
      vr_parc
      dt_reserva
      num_parc_ent
      dt_primeira_parc
      obs_ent
    }
    itens {
      id_lcto
      id_lote
      id_reserva
      vrLote
      produto
      quadra
      lote
    }
    compradores {
      bairro
      cep
      cidade
      comp_titular
      complemento
      cpf
      email
      estado
      estado_civil
      fone1
      fone2
      fone3
      id_lcto
      id_nacionalidade
      nacionalidade
      id_profissao
      profissao
      id_reserva
      local_trab
      logradouro
      nome
      numero
      rg
      documentos {
        id
        nome
      }
      dt_nascimento
      obs
      conjuge
    }
  }
}
`;

export const atualizaReservaLoteTempAPI = idLote =>
  graphqlRequest({
    query: queryAtualizaReservaLoteTemp,
    variables: { idLote },
  });

export const deleteDocCompAPI = id =>
  graphqlRequest({
    query: queryDeleteDocComp,
    variables: { id },
  });

export const saveReservaAPI = (reserva, itens, compradores) =>
  graphqlRequest({
    query: querySaveReserva,
    variables: { reserva, itens, compradores },
  });

export const deleteLoteTemp = idLote =>
  graphqlRequest({
    query: queryDeletaLoteTemp,
    variables: { id_lote: idLote },
  });

export const reservaLoteTemp = idLote =>
  graphqlRequest({
    query: queryReservaLoteTemp,
    variables: { id_lote: idLote },
  });

export const getVrPrestacao = (vrCapital, vrTaxaJuros, numParc) =>
  graphqlRequest({
    query: queryGetVrPrestacao,
    variables: { vrCapital, vrTaxaJuros, numParc },
  }).then(data => data.getVrPrestacao);

export const findEnderecoByCep = cep =>
  graphqlRequest({
    query: queryGetEndByCep,
    variables: { cep },
  }).then(data => data.findEnderecoByCep);

export const findReservaById = idReserva =>
  graphqlRequest({
    query: queryFindReservaById,
    variables: { id_lcto: idReserva },
  }).then(data => data.findReservaById);

const queryDeleteReserva = `
mutation
  deleteReserva($id_lcto: Int!) {
  	deleteReserva(id_lcto: $id_lcto) {
    	id_lcto
  	}
}
`;

export const findReservaByCorretorAPI = idCorretor =>
  axios.post(API_URL, {
    query: queryFindReserva,
    variables: {
      idCorretor,
    },
  });

export const deleteReservaAPI = idLcto =>
  axios
    .post(
      API_URL,
      {
        query: queryDeleteReserva,
        variables: {
          id_lcto: idLcto,
        },
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => {
      if (res.data.errors) {
        const message = res.data.errors.map(e => e.message).join(',');
        throw new Error(message);
      }
      return res;
    });
