import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Col,
  Card,
  CardBody,
  Input,
  Button,
  Row,
  Form,
  FormGroup,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import axios from 'axios';
import { getUserProfile } from '../store/reducers/user';
import { API_URL } from '../pages/reserva/ReservaAPI';

const queryUpdateSenha = `
  mutation
    updateSenhaCorretor($senha: String!, $idLcto: Int!) {
      updateSenhaCorretor(
        senha: $senha,
        id_lcto: $idLcto) {
          id_lcto
        }
    }
`;

class AlterarSenha extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
      novaSenha: '',
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    try {
      const { novaSenha } = this.state;
      console.log('Aqui', this.state.user.id_lcto);
      axios.post(
        API_URL,
        {
          query: queryUpdateSenha,
          variables: {
            senha: novaSenha,
            idLcto: this.state.user.id_lcto,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      this.props.history.push('/');
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { novaSenha } = this.state;
    return (
      <Container>
        <Card body style={{ borderRadius: '10px', border: '1px solid #dcdce6' }}>
          <h2 style={{ textAlign: 'center' }}>Alterar Senha</h2>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/perfil">Minha Conta</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Alterar Senha</BreadcrumbItem>
          </Breadcrumb>
          <CardBody>
            <Form onSubmit={this.handleSubmit}>
              <FormGroup row>
                <Label for="novaSenha">Nova senha</Label>
                <Input
                  type="password"
                  name="novaSenha"
                  id="novaSenha"
                  value={novaSenha}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              <Row form>
                <Col md={2}>
                  <Button color="success" type="submit" block style={{ marginBottom: '4px' }}>
                    Salvar
                  </Button>
                </Col>
                <Col md={2}>
                  <Link to="/perfil">
                    <Button color="secondary" block style={{ marginBottom: '4px' }}>
                      Cancelar
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getUserProfile(state),
  };
};

AlterarSenha.propTypes = {
  history: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(AlterarSenha);
