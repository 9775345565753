import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdAddCircleOutline, MdBusiness, MdModeEdit, MdDelete, MdSave, MdCancel } from 'react-icons/md';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import {
  Container,
  Col,
  Row,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { AvForm, AvField, AvInput, AvFeedback, AvGroup } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import validarCpf from 'validar-cpf';
import './../../App.css';
import { saveReserva } from '../../store/actions/saveReservaAction';
import SearchLote from '../../components/SearchLote';
import {
  API_URL,
  findReservaById,
  deleteLoteTemp,
  reservaLoteTemp,
  deleteDocCompAPI,
  atualizaReservaLoteTempAPI,
  findEnderecoByCep,
  getVrPrestacao,
} from '../../pages/reserva/ReservaAPI';
import SearchNacionalidade from '../../components/SearchNacionalidade';
import SearchProfissao from '../../components/SearchProfissao';
import SearchLoteamento from '../../components/SearchLoteamento';
import { getUserProfile } from '../../store/reducers/user';
import api from '../../api';

const queryQuadraLote = `
  query($id_produto: Int!) {
    findQuadraLote(id_produto: $id_produto) {
      idproduto
      descricao
      lote
      quadra
      valor
    }    
  }
`;

let AtuReservaTemp;

class ReservaCreateComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reserva: {
        id_lcto: null,
        idProd: 0,
        // eslint-disable-next-line react/prop-types
        idCorretor: this.props.user.id_lcto,
        vrEnt: 0,
        dtEnt: '',
        numParc: 1,
        vrParc: null,
        diaPag: '1',
        idEmpresa: 1,
        fgStatus: 1,
        dtReserva: moment(new Date()).format('YYYY-MM-DD'),
        numParcEnt: '',
        dtPrimeiraParc: '',
        obsEnt: '',
        idLoteamento: 0,
      },
      reservaItens: [],
      comprador: {
        nome: '',
        rg: '',
        cpf: '',
        estadoCivil: 'Solteiro',
        idNacionalidade: '',
        idProfissao: '',
        locTrab: '',
        fone1: '',
        fone2: '',
        fone3: '',
        email: '',
        compTitular: 'S',
        idReserva: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: 'SP',
        cep: '',
        complemento: '',
        documentos: [],
        dtNascimento: '',
        obs: '',
        conjuge: '',
      },
      compradores: [],
      lotes: [],
      vrTotLote: 0,
      modal: false,
      errorComp: '',
      titDadosComp: 'Inserindo dados do comprador',
      cepAut: false,
      status: 'Dados do Lote - Inserindo',
      ...props,
    };

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/prop-types
    const { id } = this.props.match.params;
    if (id !== undefined && localStorage.getItem('reserva') === null) {
      this.fetchReserva(parseInt(id, 10));
      this.setLocalStorage(null);
    }
    this.handleAtuReservaLoteTemp();
    this.getLocalStorage();
  }

  // eslint-disable-next-line no-shadow
  componentDidUpdate(_, prevState) {
    this.setLocalStorage(prevState);
  }

  componentWillUnmount() {
    clearTimeout(AtuReservaTemp);
  }

  setLocalStorage(prevState) {
    const { reserva, reservaItens, comprador, compradores, vrTotLote } = this.state;

    if (prevState === null) {
      localStorage.setItem('reserva', JSON.stringify(reserva));
      localStorage.setItem('reservaItens', JSON.stringify(reservaItens));
      localStorage.setItem('comprador', JSON.stringify(comprador));
      localStorage.setItem('compradores', JSON.stringify(compradores));
      localStorage.setItem('vrTotLote', JSON.stringify(vrTotLote));
    } else {
      if (prevState.reserva !== reserva) {
        localStorage.setItem('reserva', JSON.stringify(reserva));
      }

      if (prevState.reservaItens !== reservaItens) {
        localStorage.setItem('reservaItens', JSON.stringify(reservaItens));
      }

      if (prevState.comprador !== comprador) {
        localStorage.setItem('comprador', JSON.stringify(comprador));
      }

      if (prevState.compradores !== compradores) {
        localStorage.setItem('compradores', JSON.stringify(compradores));
      }

      if (prevState.vrTotLote !== vrTotLote) {
        localStorage.setItem('vrTotLote', JSON.stringify(vrTotLote));
      }
    }
  }

  getLocalStorage() {
    const reserva = localStorage.getItem('reserva');
    const reservaItens = localStorage.getItem('reservaItens');
    const compradores = localStorage.getItem('compradores');
    const vrTotLote = localStorage.getItem('vrTotLote');

    if (reserva) {
      this.setState({ reserva: JSON.parse(reserva) });
    }

    if (reservaItens) {
      this.setState({ reservaItens: JSON.parse(reservaItens) });
    }

    if (compradores) {
      this.setState({ compradores: JSON.parse(compradores) });
    }

    if (vrTotLote) {
      this.setState({ vrTotLote: JSON.parse(vrTotLote) });
    }
  }

  getQuadraLote = async () => {
    try {
      const { idProd } = this.state.reserva;

      if (idProd !== 0 && idProd !== undefined) {
        const reserva = this.state.reserva;
        const reservaItens = this.state.reservaItens;
        const response = await axios.post(
          API_URL,
          {
            query: queryQuadraLote,
            variables: {
              id_produto: idProd,
            },
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        this.setState({
          reserva: {
            ...reserva,
            idProd: 0,
            quadra: response.data.data.findQuadraLote.quadra,
            lote: response.data.data.findQuadraLote.lote,
            vrLote: response.data.data.findQuadraLote.valor,
          },
          reservaItens: [
            ...reservaItens,
            {
              idLote: response.data.data.findQuadraLote.idproduto,
              produto: response.data.data.findQuadraLote.descricao,
              quadra: response.data.data.findQuadraLote.quadra,
              lote: response.data.data.findQuadraLote.lote,
              vrLote: response.data.data.findQuadraLote.valor,
            },
          ],
        });
        this.totalizaVrLote();
        reservaLoteTemp(idProd);
        this.handleChangeVrEntrada();
      } else {
        // eslint-disable-next-line no-alert
        window.alert('Nenhum lote informado, selecione um lote para prosseguir!');
      }
    } catch (err) {
      const { error } = this.state;
      this.setState({
        ...error,
        error: err.message,
      });
    }
  };

  fetchReserva = id => {
    try {
      findReservaById(id).then(bundle => {
        this.setState(
          {
            reserva: {
              id_lcto: bundle.reserva.id_lcto,
              idProd: bundle.reserva.id_produto,
              idCorretor: bundle.reserva.id_corretor,
              vrEnt: `${bundle.reserva.vr_entrada}`,
              dtEnt: moment(bundle.reserva.dt_entrada).format('YYYY-MM-DD'),
              numParc: bundle.reserva.num_parc,
              vrParc: bundle.reserva.vr_parc,
              diaPag: bundle.reserva.dia_pag,
              idEmpresa: bundle.reserva.id_empresa,
              fgStatus: bundle.reserva.fg_status,
              dtReserva: moment(bundle.reserva.dt_reserva).format('YYYY-MM-DD'),
              numParcEnt: bundle.reserva.num_parc_ent,
              dtPrimeiraParc: moment(bundle.reserva.dt_primeira_parc).format('YYYY-MM-DD'),
              obsEnt: bundle.reserva.obs_ent,
            },
            status: 'Dados do Lote - Editando',
            reservaItens: bundle.itens.map(it => ({
              idLote: it.id_lote,
              vrLote: it.vrLote,
              quadra: it.quadra,
              lote: it.lote,
              produto: it.produto,
            })),
            compradores: bundle.compradores.map(co => ({
              nome: co.nome,
              rg: co.rg,
              cpf: co.cpf,
              estadoCivil: co.estado_civil,
              idNacionalidade: co.id_nacionalidade,
              idProfissao: co.id_profissao,
              locTrab: co.local_trab,
              fone1: co.fone1,
              fone2: co.fone2,
              fone3: co.fone3,
              email: co.email,
              compTitular: co.comp_titular,
              idReserva: co.id_reserva,
              logradouro: co.logradouro,
              numero: co.numero,
              bairro: co.bairro,
              cidade: co.cidade,
              estado: co.estado,
              cep: co.cep,
              complemento: co.complemento,
              documentos: co.documentos,
              dtNascimento: moment(co.dt_nascimento).format('YYYY-MM-DD'),
              obs: co.obs,
              conjuge: co.conjuge,
            })),
          },
          this.totalizaVrLote
        );
      });
    } catch (err) {
      const { error } = this.state;
      this.setState({
        ...error,
        error: err.message,
      });
    }
  };

  totalizaVrLote = () => {
    const sum = _.sumBy(this.state.reservaItens, function totLote(reserva) {
      return reserva.vrLote;
    });
    this.setState(
      {
        vrTotLote: sum,
      },
      this.handleChangeVrEntrada
    );
  };

  clearReservaTemp = () => {
    this.state.reservaItens.map(it => deleteLoteTemp(it.idLote));
    localStorage.clear();
  };

  // Adiciona ou modifica o comprador
  addComprador = (event, errors) => {
    if (errors.length === 0 && this.state.errorComp.length === 0) {
      event.persist();
      const { comprador, compradorIndex, compradores } = this.state;
      if (compradorIndex === null) {
        this.setState({
          compradores: [...compradores, comprador],
        });
      } else {
        this.setState({
          compradores: [...compradores.slice(0, compradorIndex), comprador, ...compradores.slice(compradorIndex + 1)],
        });
      }
      this.toggle();
    }
  };

  // Abre o modal do comprador
  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
      compradorIndex: null,
      comprador: {
        nome: '',
        rg: '',
        cpf: '',
        estadoCivil: 'Solteiro',
        idNacionalidade: '1',
        idProfissao: '',
        locTrab: '',
        fone1: '',
        fone2: '',
        fone3: '',
        email: '',
        compTitular: 'S',
        idReserva: '',
        logradouro: '',
        numero: '',
        bairro: '',
        cidade: '',
        estado: 'SP',
        cep: '',
        complemento: '',
        documentos: [],
        dtNascimento: '',
        obs: '',
        conjuge: '',
      },
      titDadosComp: 'Inserindo dados do comprador',
      errorComp: '',
      cepAut: false,
    }));
  }

  // Evento ao clicar no botão editar dos compradores
  handleEditComprador = ev => {
    const el = ev.target.closest('button');
    const rowIndex = Number.parseInt(el.dataset.index, 10);
    this.setState(prevState => ({
      comprador: { ...this.state.compradores[rowIndex] },
      compradorIndex: rowIndex,
      modal: !prevState.modal,
      titDadosComp: 'Editando dados do comprador',
    }));
  };

  // Após gravar a reserva é chamado este evento
  goHome = () => {
    this.history.back();
  };

  // A cada modificação dos dados do lote é disparado este evento
  handleChange = e => {
    const reserva = this.state.reserva;
    const { name, value } = e.target;

    if (name === 'idLoteamento') {
      this.setState({
        reserva: {
          ...reserva,
          idProd: 0,
          [name]: value,
        },
      });
    } else {
      this.setState({
        reserva: {
          ...reserva,
          [name]: value,
        },
      });
    }
  };

  // A cada modificação dos dados do comprador é disparado este evento
  handleChangeComprador = e => {
    const comprador = this.state.comprador;
    const { name, value } = e.target;

    this.setState({
      comprador: {
        ...comprador,
        [name]: value.toUpperCase(),
      },
    });
  };

  saveReserva = (event, errors) => {
    let erros = '';
    if (this.state.reservaItens.length === 0) {
      erros += 'Informe pelo menos um lote para prosseguir.';
    }

    if (this.state.compradores.length === 0) {
      erros += ' - Informe pelo menos um comprador para prosseguir.';
    }

    this.setState({
      error: erros,
    });

    if (errors.length === 0 && this.state.error === '') {
      event.preventDefault();
      this.props.saveReserva(this.state).then(res => {
        if (res.type === 'SAVE_RESERVA_SUCCESS') {
          localStorage.clear();
          this.props.history.push('/');
        }
      });
    }
  };

  handleDeleteComprador = rowIndex => {
    const { compradores } = this.state;
    const newCompradores = compradores.filter((item, j) => rowIndex !== j);
    this.setState({
      compradores: newCompradores,
    });
  };

  handleDeleteLotes = (rowIndex, row) => {
    const { reservaItens } = this.state;
    const newReservaItens = reservaItens.filter((item, j) => rowIndex !== j);

    this.setState(
      {
        reservaItens: newReservaItens,
      },
      this.totalizaVrLote,
      deleteLoteTemp(row.idLote)
    );
  };

  // Monta os botões de editar e excluir dos compradores
  buttonFormatter = (cell, row, rowIndex) => {
    return (
      <div>
        <Button color="warning" className="buttonEditReserva" data-index={rowIndex} onClick={this.handleEditComprador}>
          <MdModeEdit />
          Editar
        </Button>{' '}
        <Button
          color="danger"
          className="buttonEditReserva"
          data-index={rowIndex}
          disabled={this.state.reserva.fgStatus !== 1 && this.state.reserva.fgStatus !== 6}
          onClick={() => {
            // eslint-disable-next-line no-alert
            if (window.confirm(`Confirma a exclusão do comprador: ${row.nome}?`)) this.handleDeleteComprador(rowIndex);
          }}
        >
          <MdDelete />
          Excluir
        </Button>
      </div>
    );
  };

  // Monta o botão de excluir os lotes
  buttonFormatterLotes = (cell, row, rowIndex) => {
    return (
      <div>
        <Button
          color="danger"
          className="buttonEditReserva"
          data-index={rowIndex}
          disabled={this.state.reserva.fgStatus !== 1 && this.state.reserva.fgStatus !== 6}
          onClick={() => {
            // eslint-disable-next-line no-alert
            if (window.confirm(`Confirma a exclusão do lote: ${row.produto}?`)) this.handleDeleteLotes(rowIndex, row);
          }}
        >
          <MdDelete />
          Excluir
        </Button>
      </div>
    );
  };

  handleUpload = ev => {
    this.setState({ uploading: true });
    const file = ev.target.files[0];
    const nome = ev.target.value.replace('C:\\fakepath\\', '');
    api.docs
      .upload(file)
      .then(id => {
        const comprador = { ...this.state.comprador };
        comprador.documentos.push({ id, nome });
        this.setState({ uploading: false, comprador });
      })
      .catch(err => {
        this.setState({ uploading: false, uploadError: err.message });
      });
  };

  handleDeleteDoc = id => {
    deleteDocCompAPI(id);
    const { comprador } = this.state;
    const { documentos } = this.state.comprador;
    const newDocumentos = documentos.filter(it => it.id !== id);
    this.setState({
      comprador: {
        ...comprador,
        documentos: newDocumentos,
      },
    });
  };

  handleAtuReservaLoteTemp = async () => {
    try {
      this.state.reservaItens.map(it => atualizaReservaLoteTempAPI(it.idLote));
      AtuReservaTemp = setTimeout(this.handleAtuReservaLoteTemp, 10000);
    } catch (err) {
      console.error(err.message);
    }
  };

  handleVerificaCpf = () => {
    const { cpf } = this.state.comprador;
    if (validarCpf(cpf) === false) {
      this.setState({
        errorComp: 'Informe um CPF válido para prosseguir.',
      });
    } else {
      this.setState({
        errorComp: '',
      });
    }
  };

  priceFormatter = cell => {
    numeral.locale('pt-br');
    const formatado = numeral(cell).format('$ 0,0.00');
    return <span>{formatado}</span>;
  };

  handleChangeCep = async () => {
    try {
      const { comprador } = this.state;

      if (comprador.cep.length === 8) {
        const response = await findEnderecoByCep(comprador.cep);
        this.setState({
          comprador: {
            ...comprador,
            cep: response.cep,
            logradouro: response.logradouro.toUpperCase(),
            bairro: response.bairro.toUpperCase(),
            cidade: response.cidade.toUpperCase(),
            estado: response.uf,
            complemento: response.complemento.toUpperCase(),
          },
          cepAut: !!response.logradouro,
        });
      } else {
        this.setState({
          comprador: {
            ...comprador,
            cep: '',
            logradouro: '',
            bairro: '',
            cidade: '',
            estado: '',
            complemento: '',
          },
          cepAut: false,
        });
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  convertStringToNumber = valor => {
    if (valor === '') return null;
    if (typeof valor === 'string') return parseFloat(valor.replace('.', '').replace(',', '.'), 10);
    if (isNaN(valor)) throw new Error(`Não é um número válido: ${valor}`);
    return valor;
  };

  handleChangeVrEntrada = async () => {
    const { reserva } = this.state;
    const { vrEnt, numParc } = reserva;
    let { vrTotLote } = this.state;

    vrTotLote -= this.convertStringToNumber(vrEnt);

    const response = await getVrPrestacao(vrTotLote, 0.8, numParc);
    this.setState({
      reserva: {
        ...reserva,
        vrParc: response > 0 ? response : 0,
      },
    });
  };

  render() {
    const columns = [
      {
        dataField: 'nome',
        text: 'Comprador',
      },
      {
        dataField: 'fone1',
        text: 'Fone',
      },
      {
        dataField: 'acoes',
        text: '',
        formatter: this.buttonFormatter,
      },
    ];

    const columnsLotes = [
      {
        dataField: 'produto',
        text: 'Produto',
      },
      {
        dataField: 'quadra',
        text: 'Quadra',
      },
      {
        dataField: 'lote',
        text: 'Lote',
      },
      {
        dataField: 'vrLote',
        text: 'R$ Vr. Lote',
        formatter: this.priceFormatter,
      },
      {
        dataField: 'acoes',
        text: '',
        formatter: this.buttonFormatterLotes,
      },
    ];

    const { titDadosComp, vrTotLote, reservaItens } = this.state;

    // reserva
    const {
      idProd,
      vrEnt,
      dtEnt,
      numParc,
      vrParc,
      diaPag,
      fgStatus,
      dtReserva,
      numParcEnt,
      dtPrimeiraParc,
      obsEnt,
      idLoteamento,
    } = this.state.reserva;

    // comprador
    const {
      nome,
      rg,
      cpf,
      estadoCivil,
      idNacionalidade,
      idProfissao,
      locTrab,
      fone1,
      fone2,
      fone3,
      email,
      compTitular,
      logradouro,
      numero,
      bairro,
      cidade,
      estado,
      cep,
      complemento,
      documentos,
      dtNascimento,
      obs,
      conjuge,
    } = this.state.comprador;

    const { error, errorComp, cepAut, status } = this.state;

    return (
      <Container className="background-page-main">
        <h2 style={{ textAlign: 'center' }}>Proposta para compra do imóvel</h2>
        {error && <Alert color="danger">Erro ao salvar a reserva: {error}</Alert>}
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/" onClick={this.clearReservaTemp}>
              Home
            </Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>Reserva</BreadcrumbItem>
        </Breadcrumb>
        <h4>{status}</h4>
        <AvForm onSubmit={this.saveReserva} autoComplete="off">
          <Row form>
            <Col md={2}>
              <AvGroup>
                <Label for="dtReserva">Data Reserva</Label>
                <AvInput
                  type="date"
                  name="dtReserva"
                  id="dtReserva"
                  onChange={this.handleChange}
                  value={dtReserva}
                  disabled
                  required
                />
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvField
                type="select"
                name="fgStatus"
                id="fgStatus"
                label="Status"
                onChange={this.handleChange}
                value={fgStatus}
                disabled
                required
              >
                <option value="1">Aguardando aprovação</option>
                <option value="2">Aprovada</option>
                <option value="3">Reprovada</option>
                <option value="4">Reprovada Exclusão Contrato</option>
                <option value="5">Reprovada Distrato</option>
                <option value="6">Pendente Documentação</option>
              </AvField>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Link to="/loteamento" style={{ textDecoration: 'none' }}>
                  <Button type="button" color="warning" style={{ marginTop: '31px' }} block>
                    <MdBusiness size={24} style={{ marginRight: '30' }} />
                    Ver Loteamento
                  </Button>
                </Link>
              </AvGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={4}>
              <SearchLoteamento
                name="idLoteamento"
                id="idLoteamento"
                onFocus={this.handleChange}
                onChange={this.handleChange}
                value={idLoteamento}
                errorMessage="Informe o loteamento"
              />
            </Col>
            <Col md={6}>
              <SearchLote
                name="idProd"
                id="idProd"
                onChange={this.handleChange}
                onFocus={this.handleChange}
                value={idProd}
                existentes={reservaItens.map(it => it.idLote)}
                idLoteamento={idLoteamento}
                errorMessage="Informe o produto"
              />
            </Col>
            <Col md={2}>
              <AvGroup>
                <Button
                  type="button"
                  color="primary"
                  block
                  onClick={this.getQuadraLote}
                  disabled={(fgStatus !== 1 && fgStatus !== 6) || idProd === 0}
                  style={{ marginTop: '31px' }}
                >
                  <MdAddCircleOutline size={24} style={{ marginRight: '10' }} />
                  Adicionar Lote
                </Button>
              </AvGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <BootstrapTable
                bootstrap4
                keyField="produto"
                wrapperClasses="react-bootstrap-table"
                data={reservaItens}
                columns={columnsLotes}
                striped
                hover
                condensed
                bordered={false}
                noDataIndication="Nenhum registro encontrado."
              />
            </Col>
          </Row>
          <Row form>
            <Col md={3}>
              <AvGroup>
                <Label for="vrLote">R$ Valor Total Lote(s)</Label>
                <NumberFormat
                  name="vrLote"
                  id="vrLote"
                  onChange={this.handleChange}
                  value={vrTotLote}
                  customInput={AvInput}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={''}
                  disabled
                />
                <AvFeedback>Informe o valor do lote</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label for="numParcEnt">Número Parcelas Ent.</Label>
                <AvInput
                  type="number"
                  name="numParcEnt"
                  id="numParcEnt"
                  min="0"
                  onChange={this.handleChange}
                  onBlur={this.handleChangeVrEntrada}
                  value={numParcEnt}
                />
                <AvFeedback>Informe um valor maior que zero</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label for="vrEnt">R$ Valor Entrada</Label>
                <NumberFormat
                  name="vrEnt"
                  id="vrEnt"
                  onChange={this.handleChange}
                  onBlur={this.handleChangeVrEntrada}
                  value={vrEnt}
                  customInput={AvInput}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={''}
                />
                <AvFeedback>Informe o valor da entrada</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label for="dtEnt">Data Entrada</Label>
                <AvInput type="date" name="dtEnt" id="dtEnt" onChange={this.handleChange} value={dtEnt} />
              </AvGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={2}>
              <AvGroup>
                <Label for="numParc">Número Parcelas</Label>
                <AvInput
                  type="number"
                  name="numParc"
                  id="numParc"
                  min="1"
                  onChange={this.handleChange}
                  onBlur={this.handleChangeVrEntrada}
                  value={numParc}
                  validate={{ required: true }}
                />
                <AvFeedback>Informe um valor maior que zero</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={2}>
              <AvGroup>
                <Label for="taxaJuros">Taxa Juros a.m</Label>
                <AvInput type="number" name="taxaJuros" id="taxaJuros" value={0.8} disabled />
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label for="vrParc">R$ Valor Parcela</Label>
                <NumberFormat
                  name="vrParc"
                  id="vrParc"
                  onChange={this.handleChange}
                  value={vrParc}
                  customInput={AvInput}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={''}
                  disabled
                  required
                />
                <AvFeedback>Informe o valor da parcela</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={2}>
              <AvField
                type="select"
                name="diaPag"
                id="diaPag"
                label="Dia Pagamento"
                onChange={this.handleChange}
                value={diaPag}
              >
                <option>1</option>
                <option>10</option>
                <option>20</option>
              </AvField>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label for="dtPrimeiraParc">Data Primeira Parc.</Label>
                <AvInput
                  type="date"
                  name="dtPrimeiraParc"
                  id="dtPrimeiraParc"
                  onChange={this.handleChange}
                  value={dtPrimeiraParc}
                  required
                />
              </AvGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              <AvGroup>
                <Label for="obsEnt">Observação de entrada</Label>
                <AvInput type="text" name="obsEnt" id="obsEnt" onChange={this.handleChange} value={obsEnt} />
              </AvGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>Dados do(s) Comprador(es)</h4>

              <div>
                <Button color="primary" onClick={this.toggle} disabled={fgStatus !== 1 && fgStatus !== 6}>
                  <MdAddCircleOutline size={24} style={{ marginRight: '10' }} />
                  Adicionar Comprador
                </Button>
              </div>
              <br />
              <BootstrapTable
                bootstrap4
                keyField="cpf"
                wrapperClasses="react-bootstrap-table"
                data={this.state.compradores}
                columns={columns}
                striped
                hover
                condensed
                bordered={false}
                noDataIndication="Nenhum registro encontrado."
              />
            </Col>
          </Row>
          <Row>
            <Col>{error && <Alert color="danger">Erro ao salvar a reserva: {error}</Alert>}</Col>
          </Row>
          <br />
          <Button type="submit" color="success" disabled={fgStatus !== 1 && fgStatus !== 6}>
            <MdSave style={{ marginRight: '7' }} />
            Gravar reserva
          </Button>{' '}
          <Link to="/">
            <Button color="secondary" onClick={this.clearReservaTemp}>
              <MdCancel style={{ marginRight: '7' }} />
              Cancelar
            </Button>
          </Link>
        </AvForm>
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" style={{ maxWidth: '1600px', width: '95%' }}>
          <ModalHeader toggle={this.toggle}>{titDadosComp}</ModalHeader>
          <ModalBody>
            {errorComp && <Alert color="danger">{errorComp}</Alert>}
            <AvForm onSubmit={this.addComprador} autoComplete="off">
              <Row form>
                <Col md={4}>
                  <AvGroup>
                    <Label for="nomeComprador">Comprador</Label>
                    <AvInput
                      type="text"
                      name="nome"
                      id="nome"
                      maxLength="100"
                      onChange={this.handleChangeComprador}
                      value={nome}
                      autoComplete="new-password"
                      required
                    />
                    <AvFeedback>Informe o comprador</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="rgComprador">RG</Label>
                    <AvInput
                      type="text"
                      name="rg"
                      id="rg"
                      maxLength="9"
                      required
                      placeholder="RG (somente números e letras)"
                      onChange={this.handleChangeComprador}
                      value={rg}
                    />
                    <AvFeedback>Informe o RG</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="cpfComprador">CPF</Label>
                    <AvInput
                      type="text"
                      name="cpf"
                      id="cpf"
                      maxLength="11"
                      placeholder="CPF (somente números)"
                      onChange={this.handleChangeComprador}
                      onBlur={this.handleVerificaCpf}
                      value={cpf}
                      required
                    />
                    <AvFeedback>Informe o CPF</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="estadoCivil"
                    id="estadoCivil"
                    label="Estado Civil"
                    onChange={this.handleChangeComprador}
                    value={estadoCivil}
                  >
                    <option>SOLTEIRO</option>
                    <option>CASADO</option>
                    <option>SEPARADO</option>
                    <option>DIVORCIADO</option>
                    <option>VIÚVO</option>
                  </AvField>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="compTitular"
                    id="compTitular"
                    label="Comprador Titular"
                    onChange={this.handleChangeComprador}
                    value={compTitular}
                  >
                    <option value="S">SIM</option>
                    <option value="N">NÃO</option>
                  </AvField>
                </Col>
              </Row>
              <Row form>
                <Col md={3}>
                  <AvGroup>
                    <Label for="conjuge">Cônjuge</Label>
                    <AvField
                      type="select"
                      name="conjuge"
                      id="conjuge"
                      value={conjuge}
                      onChange={this.handleChangeComprador}
                    >
                      <option />
                      {this.state.compradores.map(comprador => {
                        return <option key={comprador.cpf}>{comprador.nome}</option>;
                      })}
                    </AvField>
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <SearchNacionalidade
                    name="idNacionalidade"
                    id="idNacionalidade"
                    onFocus={this.handleChangeComprador}
                    onChange={this.handleChangeComprador}
                    value={idNacionalidade}
                    required
                    errorMessage="Informe o nacionalidade"
                  />
                </Col>
                <Col md={3}>
                  <SearchProfissao
                    name="idProfissao"
                    id="idProfissao"
                    onFocus={this.handleChangeComprador}
                    onChange={this.handleChangeComprador}
                    value={idProfissao}
                    required
                    errorMessage="Informe a profissão"
                  />
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="locTrabComp">Local de trabalho</Label>
                    <AvInput
                      type="text"
                      name="locTrab"
                      id="locTrab"
                      maxLength="40"
                      onChange={this.handleChangeComprador}
                      value={locTrab}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={3}>
                  <AvGroup>
                    <Label for="dtNascimento">Data de Nascimento</Label>
                    <AvInput
                      type="date"
                      name="dtNascimento"
                      id="dtNascimento"
                      onChange={this.handleChangeComprador}
                      value={dtNascimento}
                      required
                    />
                    <AvFeedback>Informe a data de nascimento</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="tel1Comp">Telefone 1</Label>
                    <AvInput
                      type="text"
                      name="fone1"
                      id="fone1"
                      maxLength="11"
                      placeholder="Telefone 1 (somente números)"
                      onChange={this.handleChangeComprador}
                      value={fone1}
                      autoComplete="new-password"
                      required
                    />
                    <AvFeedback>Informe o telefone</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="tel2Comp">Telefone 2</Label>
                    <AvInput
                      type="text"
                      name="fone2"
                      id="fone2"
                      placeholder="Telefone 2 (somente números)"
                      maxLength="11"
                      onChange={this.handleChangeComprador}
                      value={fone2}
                      autoComplete="new-password"
                    />
                  </AvGroup>
                </Col>
                <Col md={3}>
                  <AvGroup>
                    <Label for="tel3Comp">Telefone 3</Label>
                    <AvInput
                      type="text"
                      name="fone3"
                      id="fone3"
                      maxLength="11"
                      placeholder="Telefone 3 (somente números)"
                      onChange={this.handleChangeComprador}
                      value={fone3}
                      autoComplete="new-password"
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <AvGroup>
                    <Label for="emailComp">E-mail</Label>
                    <AvInput
                      type="email"
                      name="email"
                      id="email"
                      onChange={this.handleChangeComprador}
                      value={email}
                      autoComplete="new-password"
                    />
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="cepComp">CEP</Label>
                    <AvInput
                      type="number"
                      name="cep"
                      id="cep"
                      placeholder="CEP (somente números)"
                      onChange={this.handleChangeComprador}
                      onBlur={this.handleChangeCep}
                      value={cep}
                      autoComplete="new-password"
                      required
                    />
                    <AvFeedback>Informe o cep</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Label for="logradouroComp">Rua</Label>
                    <AvInput
                      type="text"
                      name="logradouro"
                      id="logradouro"
                      maxLength="80"
                      onChange={this.handleChangeComprador}
                      value={logradouro}
                      autoComplete="new-password"
                      required
                      disabled={!!cepAut}
                    />
                    <AvFeedback>Informe a rua</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="numLograComp">Número</Label>
                    <AvInput
                      type="text"
                      name="numero"
                      id="numero"
                      maxLength="10"
                      onChange={this.handleChangeComprador}
                      value={numero}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={4}>
                  <AvGroup>
                    <Label for="bairroComp">Bairro</Label>
                    <AvInput
                      type="text"
                      name="bairro"
                      id="bairro"
                      maxLength="80"
                      onChange={this.handleChangeComprador}
                      value={bairro}
                      autoComplete="new-password"
                      required
                      disabled={!!cepAut}
                    />
                    <AvFeedback>Informe o bairro</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={4}>
                  <AvGroup>
                    <Label for="cidadeComp">Cidade</Label>
                    <AvInput
                      type="text"
                      name="cidade"
                      id="cidade"
                      maxLength="40"
                      onChange={this.handleChangeComprador}
                      value={cidade}
                      autoComplete="new-password"
                      required
                      disabled={!!cepAut}
                    />
                    <AvFeedback>Informe a cidade</AvFeedback>
                  </AvGroup>
                </Col>
                <Col md={2}>
                  <AvField
                    type="select"
                    name="estado"
                    label="Estado"
                    onChange={this.handleChangeComprador}
                    value={estado}
                    disabled={!!cepAut}
                  >
                    <option>AC</option>
                    <option>AL</option>
                    <option>AP</option>
                    <option>AM</option>
                    <option>BA</option>
                    <option>CE</option>
                    <option>DF</option>
                    <option>ES</option>
                    <option>GO</option>
                    <option>MA</option>
                    <option>MT</option>
                    <option>MS</option>
                    <option>MG</option>
                    <option>PA</option>
                    <option>PB</option>
                    <option>PR</option>
                    <option>PE</option>
                    <option>PI</option>
                    <option>RJ</option>
                    <option>RN</option>
                    <option>RS</option>
                    <option>RO</option>
                    <option>RR</option>
                    <option>SC</option>
                    <option>SP</option>
                    <option>SE</option>
                    <option>TO</option>
                  </AvField>
                </Col>
                <Col md={2}>
                  <AvGroup>
                    <Label for="complemento">Complemento</Label>
                    <AvInput
                      type="text"
                      name="complemento"
                      id="complemento"
                      maxLength="80"
                      onChange={this.handleChangeComprador}
                      value={complemento}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row form>
                <Col>
                  <AvGroup>
                    <Label for="obs">Observação</Label>
                    <AvInput
                      type="text"
                      name="obs"
                      id="obs"
                      maxLength="400"
                      onChange={this.handleChangeComprador}
                      value={obs}
                    />
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Alert color="info">
                    <input
                      type="file"
                      name="files"
                      disabled={this.state.reserva.fgStatus !== 1 && this.state.reserva.fgStatus !== 6}
                      id="file"
                      style={{ display: 'none' }}
                      onChange={this.handleUpload}
                    />
                    <label htmlFor="file">
                      <strong>Escolha um arquivo</strong>
                      <span> ou arraste até aqui para adicionar documentos.</span>.
                    </label>
                  </Alert>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ListGroup>
                    {documentos.map(d => (
                      <ListGroupItem key={d.id}>
                        <Row>
                          <Col md={11}>
                            <a href={`${process.env.REACT_APP_BASE_URL}/download/${d.id}/${d.nome}`} download>
                              {d.nome}
                            </a>{' '}
                          </Col>
                          <Col md={1}>
                            <Button
                              type="button"
                              color="danger"
                              disabled={this.state.reserva.fgStatus !== 1 && this.state.reserva.fgStatus !== 6}
                              onClick={() => {
                                // eslint-disable-next-line no-alert
                                if (window.confirm(`Confirma a exclusão do documento: ${d.nome}?`))
                                  this.handleDeleteDoc(d.id);
                              }}
                            >
                              <MdDelete style={{ marginRight: '7' }} />
                              Excluir
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              {errorComp && <Alert color="danger">{errorComp}</Alert>}
              <ModalFooter>
                <Button type="submit" color="success" disabled={fgStatus !== 1 && fgStatus !== 6}>
                  <MdSave style={{ marginRight: '7' }} />
                  Gravar Comprador
                </Button>{' '}
                <Button color="secondary" onClick={this.toggle}>
                  <MdCancel style={{ marginRight: '7' }} />
                  Cancelar
                </Button>
              </ModalFooter>
            </AvForm>
          </ModalBody>
        </Modal>
        <br />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    idReserva: state.saveReservaReducer.idReserva,
    loading: state.saveReservaReducer.loading,
    error: state.saveReservaReducer.error,
    user: getUserProfile(state),
  };
};

ReservaCreateComponent.defaultProps = {
  idReserva: null,
  error: null,
};

ReservaCreateComponent.propTypes = {
  saveReserva: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  // error: PropTypes.string,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default connect(mapStateToProps, { saveReserva })(ReservaCreateComponent);
