import { combineReducers } from 'redux';
import { LOGIN, LOGOFF } from '../actions/authActions';

export const getUserProfile = state => state.user.profile;

const profile = (state = null, action) => {
  switch (action.type) {
    case LOGIN:
      return action.data;
    case LOGOFF:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  profile,
});
