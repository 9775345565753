const INITIAL_STATE = {
  reservas: [],
  loading: false,
  error: false,
};

export default function findReserva(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FIND_RESERVA_REQUEST':
      return {
        reservas: [],
        loading: true,
        error: false,
      };
    case 'FIND_RESERVA_SUCCESS':
      return {
        reservas: action.reservas,
        loading: false,
        error: false,
      };
    case 'FIND_RESERVA_ERROR':
      return {
        reservas: [],
        loading: false,
        error: true,
      };
    default:
      return state;
  }
}
