import { findReservaByCorretorAPI } from '../../pages/reserva/ReservaAPI';

export const findReservaRequest = () => {
  return {
    type: 'FIND_RESERVA_REQUEST',
    loading: true,
    error: false,
  };
};

export const findReservaSuccess = reservas => {
  return {
    type: 'FIND_RESERVA_SUCCESS',
    reservas,
    loading: false,
    error: false,
  };
};

export const findReservaError = () => {
  return {
    type: 'FIND_RESERVA_ERROR',
    loading: false,
    error: true,
  };
};

export const findReserva = idCorretor => {
  return dispatch => {
    dispatch(findReservaRequest());
    findReservaByCorretorAPI(idCorretor)
      .then(response => dispatch(findReservaSuccess(response.data.data.findReservaByIdCorretor)))
      .catch(err => dispatch(findReservaError(err.message)));
  };
};
