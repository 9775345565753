import { combineReducers } from 'redux';
import findReservaReducer from './findReservaReducer';
import saveReservaReducer from './saveReservaReducer';
import deleteReservaReducer from './deleteReservaReducer';
import saveCompradorReducer from './saveCompradorReducer';
import user from './user';

const rootReducer = combineReducers({
  findReservaReducer,
  saveReservaReducer,
  deleteReservaReducer,
  saveCompradorReducer,
  user,
});

export default rootReducer;
