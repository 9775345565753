const INITIAL_STATE = {
  comprador: null,
  loading: false,
  error: null,
};

export default function saveComprador(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SAVE_COMPRADOR_REQUEST':
      return {
        comprador: null,
        loading: true,
        error: null,
      };
    case 'SAVE_COMPRADOR_SUCCESS':
      return {
        comprador: action.comprador,
        loading: false,
        error: null,
      };
    case 'SAVE_COMPRADOR_ERROR':
      return {
        comprador: null,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
