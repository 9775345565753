const INITIAL_STATE = {
  loading: false,
  error: false,
};

export default function deleteReserva(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'DELETE_RESERVA_REQUEST':
      return {
        loading: true,
        error: false,
      };
    case 'DELETE_RESERVA_SUCCESS':
      return {
        loading: false,
        error: false,
      };
    case 'DELETE_RESERVA_ERROR':
      return {
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
}
