import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { API_URL } from '../pages/reserva/ReservaAPI';

const queryLotes = `
  query($descProduto: String!, $idLoteamento: Int!) {
    findLotesByIdLoteamento(descProduto: $descProduto, idLoteamento: $idLoteamento) {
      idproduto
      descricao
    }
  }
`;

class SearchLote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lotes: [],
      defaultLote: 'Nenhum lote disponível.',
    };
  }

  componentDidMount() {
    this.findLotes();
  }

  componentDidUpdate(prevProps) {
    if (this.props.idLoteamento !== prevProps.idLoteamento) {
      this.findLotes();
    }
  }

  findLotes = async () => {
    try {
      const response = await axios.post(API_URL, {
        query: queryLotes,
        variables: {
          descProduto: '',
          idLoteamento: this.props.idLoteamento,
        },
      });

      this.setState({
        lotes: response.data.data.findLotesByIdLoteamento,
      });

      setTimeout(this.findLotes, 1000);
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const { existentes, idLoteamento, ...rest } = this.props;
    const { defaultLote } = this.state;

    const lotes = this.state.lotes.map(
      lote =>
        !existentes.find(e => e === lote.idproduto) && (
          <option key={lote.idproduto} value={lote.idproduto}>
            {lote.descricao}
          </option>
        )
    );

    return lotes.length === 0 ? (
      <AvField name="lotes" value={defaultLote} label="Produto" disabled />
    ) : (
      <AvField type="select" name="lotes" label="Produto" {...rest}>
        {lotes}
      </AvField>
    );
  }
}

SearchLote.defaultProps = {
  existentes: [],
  idLoteamento: 0,
};

SearchLote.propTypes = {
  existentes: PropTypes.arrayOf(PropTypes.number),
  idLoteamento: PropTypes.number.isRequired,
};

export default connect(null, null)(SearchLote);
