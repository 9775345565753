export const LOGIN = 'LOGIN';
export const LOGOFF = 'LOGOFF';

export const loginSuccess = data => ({
  type: LOGIN,
  data,
});

export const logoff = () => ({
  type: LOGOFF,
});
