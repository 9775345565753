import React, { Component } from 'react';
import { AvField } from 'availity-reactstrap-validation';
import axios from 'axios';
import { API_URL } from '../pages/reserva/ReservaAPI';

const queryLoteamentos = `
  query($descLoteamento: String!) {
    findLoteamentos(descLoteamento: $descLoteamento) {
      idgrupo
      descricao
    }
  }
`;

const variables = {
  descLoteamento: '',
};

class SearchLoteamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loteamentos: [],
    };
  }

  componentDidMount() {
    this.findLoteamentos();
  }

  findLoteamentos = async () => {
    try {
      const response = await axios.post(API_URL, {
        query: queryLoteamentos,
        variables,
      });

      this.setState({
        loteamentos: response.data.data.findLoteamentos,
      });
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const loteamentos = this.state.loteamentos.map(loteamento => (
      <option key={loteamento.idgrupo} value={loteamento.idgrupo}>
        {loteamento.descricao}
      </option>
    ));

    return (
      <AvField type="select" name="loteamentos" label="Loteamento" {...this.props}>
        {loteamentos}
      </AvField>
    );
  }
}

export default SearchLoteamento;
