import { graphqlRequest } from './request';

const userFields = `
    id_lcto
    nome
    email
    cpf
    creci
    fone
`;

const LOGIN_MUTATION = `
mutation ($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    ${userFields}
  }
}
`;

const LOGOUT_MUTATION = `
mutation {
  logout
}
`;

const ME_QUERY = `
{
	me {
    ${userFields}
  }
}
`;

export const login = ({ email, password }) =>
  graphqlRequest({
    query: LOGIN_MUTATION,
    variables: { email, password },
  }).then(data => data.login);

export const logout = () =>
  graphqlRequest({
    query: LOGOUT_MUTATION,
  });

export const me = () => graphqlRequest({ query: ME_QUERY }).then(data => data.me);

export default {
  login,
  logout,
  me,
};
