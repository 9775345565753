/* eslint-disable no-alert */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdAddCircleOutline, MdBusiness, MdModeEdit, MdDelete, MdPrint } from 'react-icons/md';
import { Container, Row, Col, Button } from 'reactstrap';
import numeral from 'numeral';
import 'numeral/locales';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import './../../App.css';
import { findReserva } from '../../store/actions/findReservaAction';
import { deleteReserva } from '../../store/actions/deleteReservaAction';
import { getUserProfile } from '../../store/reducers/user';

class ReservaListComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reservas: [],
      ...props,
    };
  }

  componentDidMount() {
    this.findReserva(this.state.user.id_lcto);
  }

  findReserva = () => {
    this.props.findReserva(this.state.user.id_lcto);
  };

  columnFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {filterElement}
        {column.text}
        {sortElement}
      </div>
    );
  };

  priceFormatter = cell => {
    numeral.locale('pt-br');
    const formatado = numeral(cell).format('$ 0,0.00');
    return <span>{formatado}</span>;
  };

  deleteReserva = async idLcto => {
    try {
      await this.props.deleteReserva(idLcto);
      await this.props.findReserva(this.state.user.id_lcto);
    } catch (err) {
      console.error(err.message);
    }
    window.location.reload();
  };

  clearStorage = () => {
    localStorage.clear();
  };

  buttonFormatter = (cell, row) => {
    return (
      <div>
        <Link to={`/printReserva/${row.id_lcto}`}>
          <Button color="info" className="buttonPrintReserva">
            <MdPrint />
            Imprimir
          </Button>
        </Link>{' '}
        <Link to={`/editReserva/${row.id_lcto}`}>
          <Button color="warning" className="buttonEditReserva" onClick={() => console.log(row.id_lcto)}>
            <MdModeEdit />
            Editar
          </Button>
        </Link>{' '}
        <Button
          color="danger"
          className="buttonEditReserva"
          onClick={() => {
            if (row.fg_status !== 1) {
              window.alert(`Não é possível excluir uma reserva ${row.fg_status === 2 ? 'aprovada.' : 'reprovada.'}`);
            } else if (window.confirm(`Confirma a exclusão da reserva número: ${row.id_lcto}?`)) {
              this.deleteReserva(row.id_lcto);
            }
          }}
        >
          <MdDelete />
          Excluir
        </Button>
      </div>
    );
  };

  render() {
    const columns = [
      {
        dataField: 'id_lcto',
        text: 'ID',
        sort: true,
        filter: textFilter(),
        headerFormatter: this.columnFormatter,
      },
      {
        dataField: 'produto',
        text: 'Lote',
        sort: true,
        filter: textFilter(),
        headerFormatter: this.columnFormatter,
      },
      {
        dataField: 'vr_lote',
        text: 'Vr. Lote',
        sort: true,
        filter: textFilter(),
        headerFormatter: this.columnFormatter,
        formatter: this.priceFormatter,
      },
      {
        dataField: 'acoes',
        text: '',
        formatter: this.buttonFormatter,
      },
    ];

    const defaultSorted = [
      {
        dataField: 'id_lcto',
        order: 'desc',
      },
    ];

    const { reservas } = this.props;
    const { loading, error } = this.props;

    return (
      <Container fluid className="background-page-main">
        {error && <div>Erro ao salvar os dados: {error}</div>}
        <Row>
          <Col>
            <h2 style={{ textAlign: 'center', marginTop: '10px' }}>Reservas</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={2} style={{ marginTop: '10px' }}>
            <Link to="/addReserva" style={{ textDecoration: 'none' }}>
              <Button color="primary" disabled={loading} block onClick={this.clearStorage()}>
                <MdAddCircleOutline size={24} color="#fff" style={{ marginRight: '30' }} />
                Adicionar Reserva
              </Button>
            </Link>
          </Col>
          <Col sm={2} style={{ marginTop: '10px' }}>
            <Link to="/loteamento" style={{ textDecoration: 'none' }}>
              <Button color="warning" disabled={loading} block onClick={this.clearStorage()}>
                <MdBusiness size={24} style={{ marginRight: '30' }} />
                Ver Loteamentos
              </Button>
            </Link>
          </Col>
        </Row>
        <br />
        {!loading && (
          <Row>
            <Col>
              <BootstrapTable
                wrapperClasses={this.state.resolucao}
                bootstrap4
                keyField="id_lcto"
                data={reservas}
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                striped
                hover
                bordered={false}
                noDataIndication="Nenhum registro encontrado."
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    reservas: state.findReservaReducer.reservas,
    loading: state.findReservaReducer.loading,
    error: state.findReservaReducer.error,
    user: getUserProfile(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    findReserva: idCorretor => dispatch(findReserva(idCorretor)),
    deleteReserva: idLcto => dispatch(deleteReserva(idLcto)),
  };
};

ReservaListComponent.defaultProps = {
  error: null,
};

ReservaListComponent.propTypes = {
  reservas: PropTypes.instanceOf(Array).isRequired,
  findReserva: PropTypes.func.isRequired,
  deleteReserva: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservaListComponent);
