import axios from 'axios';

export const API_URL = '/graphql';

export const graphqlRequest = ({ query, variables }) => {
  const transport = axios.create({
    withCredentials: true,
  });

  return transport
    .post(API_URL, {
      query,
      variables,
    })
    .then(res => {
      const { data, error, errors } = res.data;
      let message;
      if (errors) {
        message = errors.map(d => d.message).join('\n');
      }
      if (error) {
        message = error.message;
      }
      if (message) {
        throw new Error(message);
      }
      return data;
    });
};
