import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import './styles.css';
import ColinaVerde from '../../static/loteamentos/colina-verde.jpg';
import ColinaVerde2 from '../../static/loteamentos/colina-verde-||.jpg';
import PorDoSol from '../../static/loteamentos/por-do-sol.jpg';
import AltoSaoDomingos from '../../static/loteamentos/alto-do-sao-domingos.jpg';
import JardimHortensias from '../../static/loteamentos/jardim-hortensias.jpg';
import ManoMerli from '../../static/loteamentos/mano-merli.jpg';
import QuinhoMaldonado from '../../static/loteamentos/quinho-maldonado.jpg';
import ZairaZonta from '../../static/loteamentos/zaira-zonta-merli.jpg';

const items = [
  { src: `${ManoMerli}` },
  { src: `${ColinaVerde}` },
  { src: `${ColinaVerde2}` },
  { src: `${ZairaZonta}` },
  { src: `${QuinhoMaldonado}` },
  { src: `${PorDoSol}` },
  { src: `${AltoSaoDomingos}` },
  { src: `${JardimHortensias}` },
];

function Loteamento() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map(item => {
    return (
      <CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card body style={{ borderRadius: '10px', border: '1px solid #dcdce6', marginBottom: '20px' }}>
            <h2 style={{ textAlign: 'center' }}>Loteamentos</h2>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/addReserva">Reserva</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Loteamentos</BreadcrumbItem>
            </Breadcrumb>
            <CardBody>
              <Carousel className="carousel" activeIndex={activeIndex} next={next} previous={previous} interval={false}>
                <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
                {slides}
                <CarouselControl
                  className="carouselControl"
                  direction="prev"
                  directionText="Anterior"
                  onClickHandler={previous}
                />
                <CarouselControl
                  className="carouselControl"
                  direction="next"
                  directionText="Próximo"
                  onClickHandler={next}
                />
              </Carousel>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Loteamento;
