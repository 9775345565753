import { deleteReservaAPI } from '../../pages/reserva/ReservaAPI';

export const deleteReservaRequest = () => {
  return {
    type: 'DELETE_RESERVA_REQUEST',
    loading: true,
    error: null,
  };
};

export const deleteReservaSuccess = () => {
  return {
    type: 'DELETE_RESERVA_SUCCESS',
    loading: false,
    error: null,
  };
};

export const deleteReservaError = message => {
  return {
    type: 'DELETE_RESERVA_ERROR',
    loading: false,
    message,
  };
};

export const deleteReserva = idLcto => {
  return dispatch => {
    dispatch(deleteReservaRequest());
    deleteReservaAPI(idLcto)
      .then(response => dispatch(deleteReservaSuccess(response.data)))
      .catch(err => dispatch(deleteReservaError(err.message)));
  };
};
