import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';
import axios from 'axios';
import { API_URL } from '../../pages/reserva/ReservaAPI';
import { getUserProfile } from '../../store/reducers/user';

const queryUpdateCorretor = `
  mutation
    updateCorretor($nome: String!, $cpf: String!, $creci: String!, $fone: String!, $id_lcto: Int!) {
      updateCorretor(
        nome: $nome,
        cpf: $cpf,
        creci: $creci,
        fone: $fone,
        id_lcto: $id_lcto) {
          id_lcto
        }
    }
`;

class Perfil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props,
    };
  }

  handleChange = e => {
    const { user } = this.state;
    const { name, value } = e.target;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    try {
      const { nome, cpf, creci, email, fone } = this.state.user;
      axios.post(
        API_URL,
        {
          query: queryUpdateCorretor,
          variables: {
            nome,
            cpf,
            creci,
            email,
            fone,
            id_lcto: this.state.user.id_lcto,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      this.props.history.push('/');
      window.location.reload();
    } catch (err) {
      console.error(err.message);
    }
  };

  render() {
    const { email, nome, cpf, creci, fone } = this.state.user;
    return (
      <Container>
        <Form onSubmit={this.handleSubmit} autoComplete="off">
          <Card body style={{ borderRadius: '10px', border: '1px solid #dcdce6' }}>
            <h2 style={{ textAlign: 'center' }}>Minha Conta</h2>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">Home</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Minha Conta</BreadcrumbItem>
            </Breadcrumb>
            <CardBody>
              <FormGroup row>
                <Label for="nome">Nome</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  onChange={this.handleChange}
                  value={nome}
                  autoComplete="new-password"
                  required
                />
              </FormGroup>
              <FormGroup row>
                <Label for="cpf">CPF</Label>
                <Input type="number" name="cpf" id="cpf" onChange={this.handleChange} value={cpf} required />
              </FormGroup>
              <FormGroup row>
                <Label for="creci">CRECI</Label>
                <Input type="number" name="creci" id="creci" onChange={this.handleChange} value={creci} required />
              </FormGroup>
              <FormGroup row>
                <Label for="email">E-mail</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  onChange={this.handleChange}
                  value={email}
                  disabled
                  required
                />
              </FormGroup>
              <FormGroup row>
                <Label for="fone">Fone</Label>
                <Input type="tel" name="fone" id="fone" onChange={this.handleChange} value={fone} required />
              </FormGroup>
              <Row form>
                <Col md={2}>
                  <Button type="submit" color="success" block style={{ marginBottom: '4px' }}>
                    Salvar
                  </Button>
                </Col>
                <Col md={2}>
                  <Link to="/updatePass">
                    <Button color="primary" block style={{ marginBottom: '4px' }}>
                      Alterar senha
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: getUserProfile(state),
  };
};

Perfil.propTypes = {
  // corretor: PropTypes.string.isRequired,
  history: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(Perfil);
